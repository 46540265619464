<template>
    <div class="announcement-wrap">
        <van-nav-bar
            class="announcement-nav-bar"
            fixed
            left-arrow
            title="站内信息"
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="announcement-main-wrap">
            <div class="announcement-main-item-wrap" v-for="(item,index) in announcementData" :key="index">
                <div class="date">{{item.created_at}}</div>
                <div class="content-box">
                    <p class="title">{{item.title}}</p>
                    <!-- <span @click="showAnnouncementDialog=true">了解详情</span> -->
                    <p>{{item.content}}</p>
                </div>
            </div>
        </div>
        <!-- 查看公告内容 -->
        <van-dialog class="announcement-content-dialog" v-model:show="showAnnouncementDialog" title="公告内容">
            123
        </van-dialog>
    </div>
</template>

<script>
import { defineComponent,onMounted,ref } from 'vue'
import {getNewsList} from '@/api/api'

export default defineComponent({
    setup () {
        // 点击导航栏左侧返回键
        const onClickLeft = () => {
            history.back()
        }
        // 获取数据
        const handleGetNewsList = () => {
            getNewsList().then(res => {
                if(res.result == 200000) {
                    announcementData.value = res.data.list
                }
            })
        }
        onMounted(() => {
            handleGetNewsList()
        })
        // 存储站内消息
        const announcementData = ref()
        // 是否显示公告内容
        const showAnnouncementDialog = ref(false)
        return {
            onClickLeft,
            announcementData,
            showAnnouncementDialog
        }
    }
})
</script>

<style lang="less" scoped>
.announcement-wrap {
    .announcement-main-wrap {
        min-height: calc(100vh - 46px);
        margin-top: 46px;
        padding: 24px 16px 16px;
        .announcement-main-item-wrap {
            padding: 0 16px;
            margin-top: 24px;
            &:first-child {
                margin-top: 0;
            }
            .date {
                width: 171px;
                height: 22px;
                margin: 0 auto 10px;
                background: var(--btnBgColor);
                border-radius: 8px;
                line-height: 22px;
                text-align: center;
                color: #fff;
            }
            .content-box {
                width: 100%;
                padding: 14px 16px;
                background: var(--boxBgColor);
                border-radius: 8px;
                color: var(--textCommonColor);
                .title {
                    margin-bottom: 10px;
                    font-weight: 500;
                    font-size: 16px;
                }
                span {
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
}
</style>